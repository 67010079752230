<template>
  <div class="mt-2 d-flex flex-column">
    <div class="d-flex align-items-center">
      <b-button
        class="py-0"
        :style="[hasChildren ? '' : 'visibility:hidden']"
        v-b-toggle="`${hexRandom}${node.id}`"
        variant="link"
        ><fa-icon icon="caret-right" class="text-muted when-closed"></fa-icon
      ><fa-icon icon="caret-down" class="text-muted when-open"></fa-icon
      ></b-button>

      <div v-if="node.id" class="custom-control custom-checkbox">
        <input
          @input="changeValue({ id: node.id, status: $event.target.checked })"
          type="checkbox"
          class="custom-control-input"
          :id="node.id"
          :checked="model.includes(node.id)"
          :value="node.id"
        />
        <label class="custom-control-label" :for="node.id">{{
          node.description
        }}</label>
      </div>
      <div v-else class="custom-control custom-checkbox">
        <input
          @click="group_click($event.target.checked)"
          :id="hexRandom"
          :checked="checkExists"
          type="checkbox"
          class="custom-control-input"
        />
        <label class="custom-control-label" :for="hexRandom">{{
          node.description
        }}</label>
      </div>
    </div>
    <b-collapse v-if="hasChildren" :id="`${hexRandom}${node.id}`" class="ml-4">
      <PermissionMenus
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :model="model"
        :checked="child.id"
        :value="child.id"
        @input="changeValue({ id: child.id })"
      />
    </b-collapse>
  </div>
</template>

<script>
import crypto from "crypto";
export default {
  name: "PermissionMenus",
  props: ["node", "value", "model"],
  data() {
    return {
      hexRandom: "",
      selectorKey: "",
    };
  },
  computed: {
    hasChildren() {
      const { children } = this.node;
      return children && children.length > 0;
    },
    checkExists() {
        
      const keys = this.node.selectorKeys ? this.node.selectorKeys : [];

      const findCommonElements = (arr1, arr2) => {
        if (arr1.length > 0 && arr2.length > 0) {
          return arr1.some((item) => arr2.includes(item));
        } else {
          return false;
        }
      };
      return findCommonElements(this.model, keys);
    },
  },
  methods: {
    group_click(v) {
      if (v) {
        _.forEach(this.node.selectorKeys, (key) => {
          if (!this.model.includes(key)) {
            this.model.push(key);
          }
        });
      } else {
        _.forEach(this.node.selectorKeys, (key) => {
          if (this.model.includes(key)) {
            const index = _.findIndex(this.model, (item) => {
              return item == key;
            });
            this.model.splice(index, 1);
          }
        });
      }      
    },
    changeValue(v) {
      if (v.status == false) {
        const index = _.findIndex(this.model, (item) => item == v.id);
        if (index >= 0) {
          this.model.splice(index, 1);
        }
      } else {
        this.model.push(v.id);
      }
    },
  },
  mounted() {
    this.hexRandom = crypto.randomBytes(8).toString("hex");
    if (this.node.selectorKey) {
      this.selectorKey = this.node.selectorKey;
    }

    //   Object.assign(this.hexRandom, crypto.randomBytes(8).toString("hex"))
  },
};
</script>

<style>
.collapsed {
    margin-right: 4px !important;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>